<template>
    <div class="contract-sub-items-modal">
        <CoreTwoColumnsRow 
            v-for="item in props.items"
            class="contract-sub-items-modal-item-row"
        >
            <template #left>
                <span>
                    {{ item.label }}
                </span>
            </template>
            <template #right>
                <span>
                    {{ item.value }}
                </span>
            </template>
        </CoreTwoColumnsRow>
        <CoreTwoColumnsRow class="contract-sub-items-modal-total-row">
            <template #left>
                <span>
                    {{ props.total.label }}
                </span>
            </template>
            <template #right>
                <span>
                    {{ props.total.value }}
                </span>
            </template>
        </CoreTwoColumnsRow>
    </div>
</template>

<script>
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow.vue'

export default {
    name: 'ContractSubItemsModal',
    
    components: {
        CoreTwoColumnsRow,
    },
    
    data() {
        return {
            showForm: false,
            props: this.$store.state.modal.data,
        }
    },

    computed: {
    },

    created() {
    },

    mounted() {
    },

    methods: {
    },
}
</script>

<style lang="scss">
.contract-sub-items-modal {
    padding: 40px;
}
.contract-sub-items-modal-item-row {
    padding: 0.6rem 0.8rem;

    &.total-row span{
        font-weight: bold;
    }

    &:nth-child(even) {
        background-color: $cn-75;
        border-radius: 4px;
    }
}

.contract-sub-items-modal-total-row {
    padding: 0.6rem 0.8rem;
    margin-top: 1rem;

    span {
        font-weight: bold;
        font-size: 2.3rem;
    }
}
</style>

