import VueI18n from 'vue-i18n'

export const vueI18nConfig = (Vue) => {
    window.messages = {}
    if (window.vuei18nLocales !== undefined) {
        Object.keys(window.vuei18nLocales).forEach(function (lang) {
            window.messages[lang] = window.vuei18nLocales[lang]
        })
    }

    Vue.use(VueI18n)

    return new VueI18n({
        locale: 'en-GB',
        fallbackLocale: 'en-GB',
        messages: window.messages,
    })
}
